var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CButton',{staticClass:"text-left shadow-none card-header",attrs:{"block":"","color":"link"},on:{"click":function($event){_vm.accordion = _vm.accordion === 0 ? false : 0}}},[_c('h5',{staticClass:"m-0"},[_vm._v("Filtros")])]),_c('CCollapse',{attrs:{"show":_vm.accordion === 0}},[_c('CCardBody',[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_vm._v(" Fecha: "),_c('b-form-group',[_c('b-form-datepicker',{class:{
                  'form-control is-invalid':_vm.$v.date.$error,
                  'form-control is-valid': !_vm.$v.date.$invalid
                },attrs:{"id":"date","placeholder":"dd/mm/yyyy","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                },"locale":"es","today-button":"","reset-button":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('b-col',{attrs:{"lg":"3"}}),_c('b-col',{attrs:{"lg":"3"}}),_c('b-col',{attrs:{"lg":"3"}},[_c('br'),_c('b-button',{attrs:{"variant":"warning","disabled":this.$v.date.$invalid},on:{"click":_vm.submit}},[_vm._v(" Consultar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }