<template>
  <div>
    <b-row>
      <b-col>
         <CCard color="light">
          <CButton
            block
            color="link"
            class="text-left shadow-none card-header"
            @click="accordion = accordion === 0 ? false : 0"
          >
            <h5 class="m-0">Notificaciones SMS</h5>
          </CButton>
          <CCollapse :show="accordion === 0">
            <CCardBody>
              <loading
                :active.sync="loading"
                :can-cancel="false"
                background-color="#fff"
                :is-full-page="true"
              ></loading>
              <b-row>         
                <b-col>
                  <b-button variant="info" @click="notify('client')">
                    Notificar cliente
                  </b-button>
                </b-col>
                <b-col>
                  <b-button variant="warning" @click="notify('walker')">
                    Notificar paseador
                  </b-button>
                </b-col>             
              </b-row>
              <br>
              <b-alert v-model="smsFlag" variant="success" dismissible>
                {{smsResult}}
              </b-alert>
            </CCardBody>
          </CCollapse>
        </CCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay"

  export default {
    name: "Notifications",
    components:{Loading},
    props: ["service"],
    data(){
      return {
        accordion: 1,
        loading: false,
        smsFlag: false,
        smsResult: ''
      }
    },
    methods: {
      notify(to){
        this.loading = true
        let notifyServiceSchedule = this.$firebase.functions().httpsCallable('notifyServiceSchedule');
       
        let petName = this.service.pet.name
        let clientPhone = this.service.client.phone       
        let walkerPhone = this.service.walker.phone
        let date = this.service.startDateTime

        let payload = {
          petName,
          walkerPhone,
          clientPhone,
          date,
          to
        }      
        
        notifyServiceSchedule(payload).then(response => {
          let data = response.data
          this.smsFlag = true
          this.smsResult = 'SMS enviado exitosamente!'
        }).catch(error => {
          console.log(error);
          alert('Error enviando SMS')

        }).finally(_=> this.loading=false )
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>