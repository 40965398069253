<template>
  <div>
    <b-alert v-model="flagErr" :variant="variant" dismissible>
      {{ errMsg }}
    </b-alert>
    <b-modal
      title="Programación de servicios"
      v-model="showAssignServiceModal"
      no-close-on-backdrop
      hide-header-close
      size="lg"
    >
      <div v-if="service != null">
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <span>
              <strong>Id:</strong> {{service.id}} <br />
              <CIcon name="cilUser" size="sm" height="48" alt="Logo" />
              {{ service.client.name }} <br />
              <CIcon name="cilDog" size="sm" height="48" alt="Logo" />
              {{ service.pet.name }} <br />
              <CIcon name="cilClock" size="sm" height="48" alt="Logo" />
              {{ service.startDateTime | formatDateTime3 }} -
              {{ service.endDateTime | formatDateTime3 }} <br />
              <strong>Barrio:</strong> {{service.client.neighborhood}} <br />
              <strong>Zona:</strong> {{service.client.zone ? service.client.zone.name : ''}} <br />
              <strong>Obs:</strong> {{ service.obs_client }} <br />
              <strong>Cliente celular:</strong> {{ service.client.phone }} <br />
              <h5>
                <a :href="service.linkTracking" v-if="service.linkTracking != null" target="_blank">Link tracking</a> <br>
              </h5>
            </span>
          </b-col>
          <b-col>
            <b-form-group 
              v-if="setWalkerSaved" 
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="9"
              label="Proveedor"
              label-for="input-horizontal">
              <v-select
                :options="walkersOpts"
                v-model="walkerSelected"
                placeholder="Selecciona"
                :class="{
                  'form-control is-invalid': $v.walkerSelected.$error,
                  'form-control is-valid': !$v.walkerSelected.$invalid
                }"
              >
              </v-select>
              <b-form-invalid-feedback v-if="!$v.walkerSelected.required">
                Debes seleccionar un proveedor
              </b-form-invalid-feedback>
            </b-form-group>

             <b-form-group v-if="setWalkerSaved"
             label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="9"
              label="Estado"
              label-for="input-horizontal">
              <!-- <span>
                <CIcon 
                  size="sm" 
                  :name="'cil-circle'" 
                  :style="{'background-color': service.serviceState.color}"
                /></span>   -->
              <v-select
                :options="stateOpts"
                v-model="stateSelected"
                placeholder="Selecciona"
                :class="{
                  'form-control is-invalid': $v.stateSelected.$error,
                  'form-control is-valid': !$v.stateSelected.$invalid
                }"
              >
              </v-select>
              <b-form-invalid-feedback v-if="!$v.stateSelected.required">
                Debes seleccionar un estado
              </b-form-invalid-feedback>
            </b-form-group>

             <b-form-group
            label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="9"
              label="Fecha"
              label-for="input-horizontal">
              <b-form-datepicker
                id="datetime"
                placeholder="dd/mm/yyyy"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                locale="es"
                v-model="service.startDateTime"              
                today-button
                reset-button
                :class="{
                  'form-control is-invalid':$v.service.startDateTime.$error,
                  'form-control is-valid': !$v.service.startDateTime.$invalid
                }"
              ></b-form-datepicker>
              <b-form-invalid-feedback v-if="!$v.service.startDateTime.required">
                Debes indicar la fecha del servicio.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
     
        <b-row >         
          <!-- startTime -->
          <b-col lg="6" v-if="setTimes">
            Hora inicio
            <b-form-group>
              <v-select
                :options="times"
                v-model="startTime"
                :class="{
                  'form-control is-invalid': $v.startTime.$error,
                  'form-control is-valid': !$v.startTime.$invalid
                }"
              >
              </v-select>
              <b-form-invalid-feedback v-if="!$v.startTime.required">
                Debes indicar la hora de inicio.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- endTime -->
          <b-col lg="6" v-if="setTimes">
            Hora fin
            <b-form-group>
              <v-select
                :options="times"
                v-model="endTime"
                :class="{
                  'form-control is-invalid': $v.endTime.$error,
                  'form-control is-valid':  !$v.endTime.$invalid
                }"
              >
              </v-select>
              <b-form-invalid-feedback v-if="!$v.endTime.required">
                Debes indicar la hora de fin.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>             
        </b-row>
        
      </div>

     <AdditionalActions
      v-if="service != null && 
        (service.serviceState.id == 1 || 
        service.serviceState.id == 134)"
        :service="service"
     />     
     <Notifications
      v-if="service && service.serviceState.id == 1 "
      :service="service"/>
     <NAS v-if="service != null && 
        (service.serviceState.id == 2 || 
        service.serviceState.id == 34)"
        :service="service"/> 
        
      <template #modal-footer>
        <div class="w-100">
          <b-row>
            <b-col>
              <b-button size="md" variant="warning" @click="submit">
                Asignar
              </b-button>
            </b-col>
            <b-col>
              <b-button size="md" variant="danger" @click="hideModal">
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import times from '../../../../utils/times'
import { required, isValid, not, sameAs } from "vuelidate/lib/validators";
import AdditionalActions from './Modal/AdditionalActions'
import Notifications from './Modal/Notifications'
import NAS from './Modal/NAS'
export default {
  name: "assignServiceModal",
  props: ["service", "showAssignServiceModal", "walkers"],
  components: {AdditionalActions, Notifications, NAS},
  data() {
    return {      
      walkerSelected: null,
      flagErr: false,
      errMsg: "",
      variant: "success",
      stateOpts: [
        {value: 1, label: 'Programado'},                
      ],
      stateSelected: null,
      times: times,
      startTime: null,
      endTime: null,
    };
  },
  validations: {
    walkerSelected: {
      required,
    },
    stateSelected: {
      required,
    },
    service: {
      startDateTime: {
        required,
      },     
    },
    startTime: {
      required
    },
    endTime: {
      required
    }
  
  },

  computed: {
    walkersOpts() {
      if (this.walkers) {
        let opts = [];
        this.walkers.map(walker => {
          let opt = {
            value: walker.id,
            label: walker.fullName
          };
          opts.push(opt);
        });
        return opts;
      }
    },
    setTimes(){
      if(this.service){
        let startTimeFound = this.times.find(time => time.label == this.service.startTime)
        if(startTimeFound != undefined){
          this.startTime = {
            value: startTimeFound.value,
            label: startTimeFound.label
          }          
        }
        
        let endTimeFound = this.times.find(time => time.label == this.service.endTime)
        if(endTimeFound != undefined) {
          this.endTime = {
            value: endTimeFound.value,
            label: endTimeFound.label
          }          
        }
        return true
      }else {
        return false
      }
    },
    setWalkerSaved(){
      if(this.service){
        if(this.service.walker != null){
          this.walkerSelected = {
            value: this.service.walker.id,
            label: this.service.walker.fullname
          }
        }
       
        if(this.service.serviceState != null){
          let item = {
            value: this.service.serviceState.id,
            label: this.service.serviceState.name,
          }
          this.setStateOpts(item)
        }
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    hideModal() {
      this.$store.commit("defShowAssignServiceModal", false);
    },
    submit() {
      this.$v.$touch();
      if (
        !this.$v.walkerSelected.$invalid &&
        !this.$v.startTime.$invalid &&
        !this.$v.endTime.$invalid) {
        this.assignService();
      } else {
        console.log("err");
      }
    },
    assignService() {
      let date = moment(this.service.startDateTime).format('YYYY-MM-DD')

      let payload = {
        serviceId: this.service.id,
        walkerId: this.walkerSelected.value,
        startDateTime: date + 'T' + this.startTime.label + ':00.000Z',
        endDateTime: date + 'T' + this.endTime.label + ':00.000Z',
        stateId: this.stateSelected.value,
        validateIsUserNull: false,
      };
      // console.log('payload: ', payload)
      let assignWalkerToService = this.$firebase.functions().httpsCallable("assignWalkerToService");
      assignWalkerToService(payload).then(res => {
          // console.log(res.data.data);
        let data = res.data.data;
        this.walkerSelected = null;
        this.flagErr = true;
        this.errMsg = data.message;
        this.variant = "success";
        this.$store.commit("defShowAssignServiceModal", false);
      }).catch(error => {
        console.log(error);
        this.flagErr = true;
        this.errMsg = error;
        this.variant = "danger";
      });
    },
    startTimeChanged(time, date, i){
      console.log('time: ', time)
    },
    setStateOpts(state){
      let stateId = state.value
      if(stateId == 1 || stateId == 2) {
        this.stateOpts = [{value: state.id, label: state.label}]
        this.stateSelected = state
      } else if (stateId == 34 || stateId == 101) {
        this.stateOpts = [
          {value: 1, label: 'Programado'},
          {value: state.id, label: state.label}
        ]
        this.stateSelected = state
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
