<template>
  <div>
    <Modal
      :service="serviceToEdit"
      :showAssignServiceModal="showAssignServiceModal"
      :walkers="walkers"/>
    <b-table
      class="customTable"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      show-empty
      bordered
      striped
      responsive
      @filtered="onFiltered"
      :selectable="true"
      @row-clicked="rowClicked"
      sticky-header="800px"
    >
      <!-- Headers walker and zone -->
      <template #thead-top="data">
        <b-tr >
          <b-th></b-th>
          <b-th          
            colspan="4"
            v-for="(walker, iWalker) in walkers"
            v-bind:key="iWalker"
            :variant="walker.zoneColor"
          >
            {{ walker.zone.name }}
          </b-th>
        </b-tr>
        <b-tr>
          <b-th colspan="1"><span class="sr-only"></span></b-th>
          <b-th
            colspan="4"
            v-for="(walker, iWalker) in walkers"
            v-bind:key="iWalker"
            :style="{ 'background-color': walker.color }"
          >
          <br>
          <br>
            <small>
              {{ walker.fullName }}
            </small>
          </b-th>
        </b-tr>
      </template>

      <template #cell(_1_a)="service">
        <b-button
          v-if="service.item._1_a != null"
          :variant="service.item._1_a.variant"
          size="sm"
          @click="cellClicked(service.item._1_a)"
        >
          <CIcon size="sm" :name="'cil-circle'" :style="{'background-color': service.item._1_a.serviceState.color}"/>
          <span> {{ service.item._1_a.value }}</span>
        </b-button>
      </template>
      <template #cell(_1_b)="service">
        <b-button
          v-if="service.item._1_b != null"
          :variant="service.item._1_b.variant"
          size="sm"          
          @click="cellClicked(service.item._1_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._1_b.serviceState.color}"
          />
          <span> {{ service.item._1_b.value }}</span>
        </b-button>
      </template>
      <template #cell(_1_c)="service">
        <b-button
          v-if="service.item._1_c != null"
          :variant="service.item._1_c.variant"
          size="sm"
          @click="cellClicked(service.item._1_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._1_c.serviceState.color}"
          />
          <span>{{ service.item._1_c.value }}</span>          
        </b-button>
      </template>
      <template #cell(_1_d)="service">
        <b-button
          v-if="service.item._1_d != null"
          :variant="service.item._1_d.variant"
          size="sm"
          @click="cellClicked(service.item._1_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._1_d.serviceState.color}"
          />
          <span>
          {{ service.item._1_d.value }}
          </span>
        </b-button>
      </template>

      <template #cell(_2_a)="service">
        <b-button
          v-if="service.item._2_a != null"
          :variant="service.item._2_a.variant"
          size="sm"
          @click="cellClicked(service.item._2_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._2_a.serviceState.color}"
          />
          <span>
            {{ service.item._2_a.value }}
          </span>
        </b-button>
      </template>
      <template #cell(_2_b)="service">
        <b-button
          v-if="service.item._2_b != null"
          :variant="service.item._2_b.variant"
          size="sm"
          @click="cellClicked(service.item._2_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._2_b.serviceState.color}"
          />
          <span>
            {{ service.item._2_b.value }}
          </span>
        </b-button>
      </template>
      <template #cell(_2_c)="service">
        <b-button
          v-if="service.item._2_c != null"
          :variant="service.item._2_c.variant"
          size="sm"
          @click="cellClicked(service.item._2_c)"
        >
         <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._2_c.serviceState.color}"
          />
          <span>
            {{ service.item._2_c.value }}
          </span>
        </b-button>
      </template>
      <template #cell(_2_d)="service">
        <b-button
          v-if="service.item._2_d != null"
          :variant="service.item._2_d.variant"
          size="sm"
          @click="cellClicked(service.item._2_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._2_d.serviceState.color}"
          />
          <span>
            {{ service.item._2_d.value }}
          </span>
        </b-button>
      </template>

      <template #cell(_3_a)="service">
        <b-button
          v-if="service.item._3_a != null"
          :variant="service.item._3_a.variant"
          size="sm"
          @click="cellClicked(service.item._3_a)"
        >
        <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._3_a.serviceState.color}"
          />
          {{ service.item._3_a.value }}
        </b-button>
      </template>
      <template #cell(_3_b)="service">
        <b-button
          v-if="service.item._3_b != null"
          :variant="service.item._3_b.variant"
          size="sm"
          @click="cellClicked(service.item._3_b)"
        >
         <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._3_b.serviceState.color}"
          />
          {{ service.item._3_b.value }}
        </b-button>
      </template>
      <template #cell(_3_c)="service">
        <b-button
          v-if="service.item._3_c != null"
          :variant="service.item._3_c.variant"
          size="sm"
          @click="cellClicked(service.item._3_c)"
        >
         <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._3_c.serviceState.color}"
          />
          {{ service.item._3_c.value }}
        </b-button>
      </template>
      <template #cell(_3_d)="service">
        <b-button
          v-if="service.item._3_d != null"
          :variant="service.item._3_d.variant"
          size="sm"
          @click="cellClicked(service.item._3_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._3_d.serviceState.color}"
          />
          {{ service.item._3_d.value }}
        </b-button>
      </template>

      <template #cell(_4_a)="service">
        <b-button
          v-if="service.item._4_a != null"
          :variant="service.item._4_a.variant"
          size="sm"
          @click="cellClicked(service.item._4_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._4_a.serviceState.color}"
          />
          {{ service.item._4_a.value }}
        </b-button>
      </template>
      <template #cell(_4_b)="service">
        <b-button
          v-if="service.item._4_b != null"
          :variant="service.item._4_b.variant"
          size="sm"
          @click="cellClicked(service.item._4_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._4_b.serviceState.color}"
          />
          {{ service.item._4_b.value }}
        </b-button>
      </template>
      <template #cell(_4_c)="service">
        <b-button
          v-if="service.item._4_c != null"
          :variant="service.item._4_c.variant"
          size="sm"
          @click="cellClicked(service.item._4_c)"
        >
         <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._4_c.serviceState.color}"
          />
          {{ service.item._4_c.value }}
        </b-button>
      </template>
      <template #cell(_4_d)="service">
        <b-button
          v-if="service.item._4_d != null"
          :variant="service.item._4_d.variant"
          size="sm"
          @click="cellClicked(service.item._4_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._4_d.serviceState.color}"
          />
          {{ service.item._4_d.value }}
        </b-button>
      </template>

      <template #cell(_5_a)="service">
        <b-button
          v-if="service.item._5_a != null"
          :variant="service.item._5_a.variant"
          size="sm"
          @click="cellClicked(service.item._5_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._5_a.serviceState.color}"
          />
          {{ service.item._5_a.value }}
        </b-button>
      </template>
      <template #cell(_5_b)="service">
        <b-button
          v-if="service.item._5_b != null"
          :variant="service.item._5_b.variant"
          size="sm"
          @click="cellClicked(service.item._5_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._5_b.serviceState.color}"
          />
          {{ service.item._5_b.value }}
        </b-button>
      </template>
      <template #cell(_5_c)="service">
        <b-button
          v-if="service.item._5_c != null"
          :variant="service.item._5_c.variant"
          size="sm"
          @click="cellClicked(service.item._5_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._5_c.serviceState.color}"
          />
          {{ service.item._5_c.value }}
        </b-button>
      </template>
      <template #cell(_5_d)="service">
        <b-button
          v-if="service.item._5_d != null"
          :variant="service.item._5_d.variant"
          size="sm"
          @click="cellClicked(service.item._5_d)"
        >
        <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._5_d.serviceState.color}"
          />
          {{ service.item._5_d.value }}
        </b-button>
      </template>

      <template #cell(_6_a)="service">
        <b-button
          v-if="service.item._6_a != null"
          :variant="service.item._6_a.variant"
          size="sm"
          @click="cellClicked(service.item._6_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._6_a.serviceState.color}"
          />
          {{ service.item._6_a.value }}
        </b-button>
      </template>
      <template #cell(_6_b)="service">
        <b-button
          v-if="service.item._6_b != null"
          :variant="service.item._6_b.variant"
          size="sm"
          @click="cellClicked(service.item._6_b)"
        >
         <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._6_b.serviceState.color}"
          />
          {{ service.item._6_b.value }}
        </b-button>
      </template>
      <template #cell(_6_c)="service">
        <b-button
          v-if="service.item._6_c != null"
          :variant="service.item._6_c.variant"
          size="sm"
          @click="cellClicked(service.item._6_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._6_c.serviceState.color}"
          />
          {{ service.item._6_c.value }}
        </b-button>
      </template>
      <template #cell(_6_d)="service">
        <b-button
          v-if="service.item._6_d != null"
          :variant="service.item._6_d.variant"
          size="sm"
          @click="cellClicked(service.item._6_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._6_d.serviceState.color}"
          />
          {{ service.item._6_d.value }}
        </b-button>
      </template>

      <template #cell(_7_a)="service">
        <b-button
          v-if="service.item._7_a != null"
          :variant="service.item._7_a.variant"
          size="sm"
          @click="cellClicked(service.item._7_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._7_a.serviceState.color}"
          />
          {{ service.item._7_a.value }}
        </b-button>
      </template>
      <template #cell(_7_b)="service">
        <b-button
          v-if="service.item._7_b != null"
          :variant="service.item._7_b.variant"
          size="sm"
          @click="cellClicked(service.item._7_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._7_b.serviceState.color}"
          />
          {{ service.item._7_b.value }}
        </b-button>
      </template>
      <template #cell(_7_c)="service">
        <b-button
          v-if="service.item._7_c != null"
          :variant="service.item._7_c.variant"
          size="sm"
          @click="cellClicked(service.item._7_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._7_c.serviceState.color}"
          />
          {{ service.item._7_c.value }}
        </b-button>
      </template>
      <template #cell(_7_d)="service">
        <b-button
          v-if="service.item._7_d != null"
          :variant="service.item._7_d.variant"
          size="sm"
          @click="cellClicked(service.item._7_d)"
        >
         <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._7_d.serviceState.color}"
          />
          {{ service.item._7_d.value }}
        </b-button>
      </template>

      <template #cell(_8_a)="service">
        <b-button
          v-if="service.item._8_a != null"
          :variant="service.item._8_a.variant"
          size="sm"
          @click="cellClicked(service.item._8_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._8_a.serviceState.color}"
          />
          {{ service.item._8_a.value }}
        </b-button>
      </template>
      <template #cell(_8_b)="service">
        <b-button
          v-if="service.item._8_b != null"
          :variant="service.item._8_b.variant"
          size="sm"
          @click="cellClicked(service.item._8_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._8_b.serviceState.color}"
          />
          {{ service.item._8_b.value }}
        </b-button>
      </template>
      <template #cell(_8_c)="service">
        <b-button
          v-if="service.item._8_c != null"
          :variant="service.item._8_c.variant"
          size="sm"
          @click="cellClicked(service.item._8_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._8_c.serviceState.color}"
          />
          {{ service.item._8_c.value }}
        </b-button>
      </template>
      <template #cell(_8_d)="service">
        <b-button
          v-if="service.item._8_d != null"
          :variant="service.item._8_d.variant"
          size="sm"
          @click="cellClicked(service.item._8_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._8_d.serviceState.color}"
          />
          {{ service.item._8_d.value }}
        </b-button>
      </template>

      <template #cell(_9_a)="service">
        <b-button
          v-if="service.item._9_a != null"
          :variant="service.item._9_a.variant"
          size="sm"
          @click="cellClicked(service.item._9_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._9_a.serviceState.color}"
          />
          {{ service.item._9_a.value }}
        </b-button>
      </template>
      <template #cell(_9_b)="service">
        <b-button
          v-if="service.item._9_b != null"
          :variant="service.item._9_b.variant"
          size="sm"
          @click="cellClicked(service.item._9_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._9_b.serviceState.color}"
          />
          {{ service.item._9_b.value }}
        </b-button>
      </template>
      <template #cell(_9_c)="service">
        <b-button
          v-if="service.item._9_c != null"
          :variant="service.item._9_c.variant"
          size="sm"
          @click="cellClicked(service.item._9_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._9_c.serviceState.color}"
          />
          {{ service.item._9_c.value }}
        </b-button>
      </template>
      <template #cell(_9_d)="service">
        <b-button
          v-if="service.item._9_d != null"
          :variant="service.item._9_d.variant"
          size="sm"
          @click="cellClicked(service.item._9_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._9_d.serviceState.color}"
          />
          {{ service.item._9_d.value }}
        </b-button>
      </template>

      <template #cell(_10_a)="service">
        <b-button
          v-if="service.item._10_a != null"
          :variant="service.item._10_a.variant"
          size="sm"
          @click="cellClicked(service.item._10_a)"          
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._10_a.serviceState.color}"
          />
          {{ service.item._10_a.value }}
        </b-button>
      </template>
      <template #cell(_10_b)="service">
        <b-button
          v-if="service.item._10_b != null"
          :variant="service.item._10_b.variant"
          size="sm"
          @click="cellClicked(service.item._10_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._10_b.serviceState.color}"
          />
          {{ service.item._10_b.value }}
        </b-button>
      </template>
      <template #cell(_10_c)="service">
        <b-button
          v-if="service.item._10_c != null"
          :variant="service.item._10_c.variant"
          size="sm"
          @click="cellClicked(service.item._10_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._10_c.serviceState.color}"
          />
          {{ service.item._10_c.value }}
        </b-button>
      </template>
      <template #cell(_10_d)="service">
        <b-button
          v-if="service.item._10_d != null"
          :variant="service.item._10_d.variant"
          size="sm"
          @click="cellClicked(service.item._10_d)"
        >
        <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._10_d.serviceState.color}"
          />
          {{ service.item._10_d.value }}
        </b-button>
      </template>

      <template #cell(_11_a)="service">
        <b-button
          v-if="service.item._11_a != null"
          :variant="service.item._11_a.variant"
          size="sm"
          @click="cellClicked(service.item._11_a)"      
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._11_a.serviceState.color}"
          />
          {{ service.item._11_a.value }}
        </b-button>
      </template>
      <template #cell(_11_b)="service">
        <b-button
          v-if="service.item._11_b != null"
          :variant="service.item._11_b.variant"
          size="sm"
          @click="cellClicked(service.item._11_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._11_b.serviceState.color}"
          />
          {{ service.item._11_b.value }}
        </b-button>
      </template>
      <template #cell(_11_c)="service">
        <b-button
          v-if="service.item._11_c != null"
          :variant="service.item._11_c.variant"
          size="sm"
          @click="cellClicked(service.item._11_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._11_c.serviceState.color}"
          />
          {{ service.item._11_c.value }}
        </b-button>
      </template>
      <template #cell(_11_d)="service">
        <b-button
          v-if="service.item._11_d != null"
          :variant="service.item._11_d.variant"
          size="sm"
          @click="cellClicked(service.item._11_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._11_d.serviceState.color}"
          />
          {{ service.item._11_d.value }}
        </b-button>
      </template>

      <template #cell(_12_a)="service">
        <b-button
          v-if="service.item._12_a != null"
          :variant="service.item._12_a.variant"
          size="sm"
          @click="cellClicked(service.item._12_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._12_a.serviceState.color}"
          />
          {{ service.item._12_a.value }}
        </b-button>
      </template>
      <template #cell(_12_b)="service">
        <b-button
          v-if="service.item._12_b != null"
          :variant="service.item._12_b.variant"
          size="sm"
          @click="cellClicked(service.item._12_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._12_b.serviceState.color}"
          />
          {{ service.item._12_b.value }}
        </b-button>
      </template>
      <template #cell(_12_c)="service">
        <b-button
          v-if="service.item._12_c != null"
          :variant="service.item._12_c.variant"
          size="sm"
          @click="cellClicked(service.item._12_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._12_c.serviceState.color}"
          />
          {{ service.item._12_c.value }}
        </b-button>
      </template>
      <template #cell(_12_d)="service">
        <b-button
          v-if="service.item._12_d != null"
          :variant="service.item._12_d.variant"
          size="sm"
          @click="cellClicked(service.item._12_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._12_d.serviceState.color}"
          />
          {{ service.item._12_d.value }}
        </b-button>
      </template>

      <template #cell(_13_a)="service">
        <b-button
          v-if="service.item._13_a != null"
          :variant="service.item._13_a.variant"
          size="sm"
          @click="cellClicked(service.item._13_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._13_a.serviceState.color}"
          />
          {{ service.item._13_a.value }}
        </b-button>
      </template>
      <template #cell(_13_b)="service">
        <b-button
          v-if="service.item._13_b != null"
          :variant="service.item._13_b.variant"
          size="sm"
          @click="cellClicked(service.item._13_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._13_b.serviceState.color}"
          />
          {{ service.item._13_b.value }}
        </b-button>
      </template>
      <template #cell(_13_c)="service">
        <b-button
          v-if="service.item._13_c != null"
          :variant="service.item._13_c.variant"
          size="sm"
          @click="cellClicked(service.item._13_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._13_c.serviceState.color}"
          />
          {{ service.item._13_c.value }}
        </b-button>
      </template>
      <template #cell(_13_d)="service">
        <b-button
          v-if="service.item._13_d != null"
          :variant="service.item._13_d.variant"
          size="sm"
          @click="cellClicked(service.item._13_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._13_d.serviceState.color}"
          />
          {{ service.item._13_d.value }}
        </b-button>
      </template>

      <template #cell(_14_a)="service">
        <b-button
          v-if="service.item._14_a != null"
          :variant="service.item._14_a.variant"
          size="sm"
          @click="cellClicked(service.item._14_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._14_a.serviceState.color}"
          />
          {{ service.item._14_a.value }}
        </b-button>
      </template>
      <template #cell(_14_b)="service">
        <b-button
          v-if="service.item._14_b != null"
          :variant="service.item._14_b.variant"
          size="sm"
          @click="cellClicked(service.item._14_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._14_b.serviceState.color}"
          />
          {{ service.item._14_b.value }}
        </b-button>
      </template>
      <template #cell(_14_c)="service">
        <b-button
          v-if="service.item._14_c != null"
          :variant="service.item._14_c.variant"
          size="sm"
          @click="cellClicked(service.item._14_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._14_c.serviceState.color}"
          />
          {{ service.item._14_c.value }}
        </b-button>
      </template>
      <template #cell(_14_d)="service">
        <b-button
          v-if="service.item._14_d != null"
          :variant="service.item._14_d.variant"
          size="sm"
          @click="cellClicked(service.item._14_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._14_d.serviceState.color}"
          />
          {{ service.item._14_d.value }}
        </b-button>
      </template>

      <template #cell(_15_a)="service">
        <b-button
          v-if="service.item._15_a != null"
          :variant="service.item._15_a.variant"
          size="sm"
          @click="cellClicked(service.item._15_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._15_a.serviceState.color}"
          />
          {{ service.item._15_a.value }}
        </b-button>
      </template>
      <template #cell(_15_b)="service">
        <b-button
          v-if="service.item._15_b != null"
          :variant="service.item._15_b.variant"
          size="sm"
          @click="cellClicked(service.item._15_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._15_b.serviceState.color}"
          />
          {{ service.item._15_b.value }}
        </b-button>
      </template>
      <template #cell(_15_c)="service">
        <b-button
          v-if="service.item._15_c != null"
          :variant="service.item._15_c.variant"
          size="sm"
          @click="cellClicked(service.item._15_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._15_c.serviceState.color}"
          />
          {{ service.item._15_c.value }}
        </b-button>
      </template>
      <template #cell(_15_d)="service">
        <b-button
          v-if="service.item._15_d != null"
          :variant="service.item._15_d.variant"
          size="sm"
          @click="cellClicked(service.item._15_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._15_d.serviceState.color}"
          />
          {{ service.item._15_d.value }}
        </b-button>
      </template>

      <template #cell(_16_a)="service">
        <b-button
          v-if="service.item._16_a != null"
          :variant="service.item._16_a.variant"
          size="sm"
          @click="cellClicked(service.item._16_a)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._16_a.serviceState.color}"
          />
          {{ service.item._16_a.value }}
        </b-button>
      </template>
      <template #cell(_16_b)="service">
        <b-button
          v-if="service.item._16_b != null"
          :variant="service.item._16_b.variant"
          size="sm"
          @click="cellClicked(service.item._16_b)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._16_b.serviceState.color}"
          />
          {{ service.item._16_b.value }}
        </b-button>
      </template>
      <template #cell(_16_c)="service">
        <b-button
          v-if="service.item._16_c != null"
          :variant="service.item._16_c.variant"
          size="sm"
          @click="cellClicked(service.item._16_c)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._16_c.serviceState.color}"
          />
          {{ service.item._16_c.value }}
        </b-button>
      </template>
      <template #cell(_16_d)="service">
        <b-button
          v-if="service.item._16_d != null"
          :variant="service.item._16_d.variant"
          size="sm"
          @click="cellClicked(service.item._16_d)"
        >
          <CIcon 
            size="sm" 
            :name="'cil-circle'" 
            :style="{'background-color': service.item._16_d.serviceState.color}"
          />
          {{ service.item._16_d.value }}
        </b-button>
      </template>

      <!-- <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="rowClicked(row.item, row.index, $event.target)"
          class="mr-1"
          >Registrar pago</b-button
        >
        <b-button size="sm" @click="row.toggleDetails"
          >{{ row.detailsShowing ? "Ocultar" : "Ver" }} Detalle</b-button
        >
      </template> -->

      <!-- <template #row-details="row">
        <b-card>
          <b-row>
            <b-col lg="12" md="12">
              <div>
                <strong>Id:</strong>
                {{ row.item }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template> -->
    </b-table>
  </div>
</template>

<script>
import Modal from './Modal'
import moment from 'moment'
export default {
  name: "calendarTable",
  components: { Modal },
  props: ['items', 'walkers', 'fields', 'services'],
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 150,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      serviceToEdit: null,
      showAssignServiceModal: false,
    };
  },
  created() {
    this.watchs()
    // console.log(this.walkers)
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClicked(item, index) {
      // console.log("tableItem: ", item);
    },
    cellClicked(serviceSelected) {
      // console.log("serviceClicked: ", serviceSelected);
      // console.log(this.services)
      this.serviceToEdit = this.services.find(service => service.id == serviceSelected.serviceId )
      if(this.serviceToEdit != undefined){
        this.showAssignServiceModal = true
        this.serviceToEdit.startTime = moment(this.serviceToEdit.startDateTime).format('HH:mm')
        this.serviceToEdit.endTime = moment(this.serviceToEdit.endDateTime).format('HH:mm')
        // console.log('serviceFound: ', this.serviceToEdit)
      }
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defShowAssignServiceModal") {
          this.showAssignServiceModal = this.$store.getters.showAssignServiceModal
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
