<template>
  <div>
    <Modal 
      :service="serviceToAssign" 
      :showAssignServiceModal="showAssignServiceModal"
      :walkers="walkers"
    />
    <CCard>
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordion = accordion === 0 ? false : 0"
      >
        <h5 class="m-0">
          Por agendar: {{ services != null ? services.length : 0 }}
        </h5>
      </CButton>
      <CCollapse :show="accordion === 0">
        <CCardBody>
          <b-row v-if="services != null && services.length > 0">
            <b-col lg="12" v-for="(service, i) in services" v-bind:key="i">
              <CCard
                class="mb-0"
                style="margin-top:0.5rem;"
                @click="assigneService(service)"
              >
                <CCardBody>
                  <strong>Id:</strong> {{service.id}} <br />
                  <CIcon name="cilUser" size="sm" height="48" alt="Logo" />
                  {{ service.client.name }} <br />
                  <CIcon name="cilDog" size="sm" height="48" alt="Logo" />
                  {{ service.pet.name }} <br />
                  <CIcon name="cilClock" size="sm" height="48" alt="Logo" />
                  {{ service.startDateTime | formatDateTime3 }} -
                  {{ service.endDateTime | formatDateTime3 }} <br />
                  <strong>Barrio:</strong> {{service.client.neighborhood}} <br />
                  <strong>Zona:</strong> {{service.client.zone ? service.client.zone.name : ''}} <br />
                  <strong>Obs:</strong> {{ service.obs_client }} <br />
                </CCardBody>
              </CCard>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment';
import Modal from "./Modal";
export default {
  name: "pendingList",
  components: { Modal },
  props: ["services", "walkers"],
  data() {
    return {
      accordion: 0,
      serviceToAssign: null,
      showAssignServiceModal: false
    };
  },
  created() {
    this.watchs()
  },
  methods: {
    assigneService(service) {
      this.serviceToAssign = service
      this.serviceToAssign.startTime = moment(service.startDateTime).format('HH:mm')
      this.serviceToAssign.endTime = moment(service.endDateTime).format('HH:mm')
       console.log('serviceToAssing: ', this.serviceToAssign)
      this.showAssignServiceModal = true
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defShowAssignServiceModal") {
          this.showAssignServiceModal = this.$store.getters.showAssignServiceModal
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
