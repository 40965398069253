<template>
  <div>

    <b-row>
      <b-col>
        <CCard color="light">
          <CButton
            block
            color="link"
            class="text-left shadow-none card-header"
            @click="onAccordion"
          >
          <!-- @click="accordion = accordion === 0 ? false : 0" -->
            <h5 class="m-0">NAS</h5>
          </CButton>
          <CCollapse :show="accordion === 0">
            <CCardBody>
                <loading
                :active.sync="loading"
                :can-cancel="false"
                background-color="#fff"
                :is-full-page="true"
              ></loading>
              <section v-if="nas != null">
                <b-row>
                  <b-col lg="6" md="6">
                    <strong>Tipo inicio:</strong> {{nas.tipo_inicio_servicio.nombre}}
                  </b-col>

                  <b-col lg="6" md="6">
                    <strong>Delta inicio minutos:</strong> {{nas.delta_minutos}}
                  </b-col>
              
                  <b-col lg="6" md="6">
                    <strong>GPS al inicio: </strong>
                    <span v-if="nas.gps_inicio">                      
                      <CIcon name="cilCheck" style="color:green" size="sm" height="48" alt="Logo" />
                    </span>
                    <span v-else>
                      <CIcon name="cilXCircle" style="color:red" size="sm" height="48" alt="Logo" />
                    </span>                    
                  </b-col>

                  <b-col lg="6" md="6">
                    <strong>Posición inicio en radio cliente:</strong>
                    <span v-if="nas.gpsinicio_en_radio">                      
                      <CIcon name="cilCheck" style="color:green" size="sm" height="48" alt="Logo" />
                    </span>
                    <span v-else>
                      <CIcon name="cilXCircle" style="color:red" size="sm" height="48" alt="Logo" />
                    </span>
                  </b-col>

                  <b-col lg="6" md="6">
                    <strong>Radio inicio a cliente (mts): </strong> {{nas.radio_inicio_mts}}
                  </b-col>
                </b-row>
             
                <br>
                <b-row v-if="service.serviceState.id == 34">
                  <b-col lg="6" md="6">
                    <strong>Tipo duración:</strong> {{nas.tipo_duracion_ser != null ? nas.tipo_duracion_ser.nombre : ''}}
                  </b-col>
                  <b-col lg="6" md="6">
                    <strong>Duración (mins):</strong> {{nas.duracion}}
                  </b-col>
                  <b-col lg="6" md="6">
                    <strong>Distancia cumplida:</strong>
                    <span v-if="nas.distancia_cumplida">                      
                      <CIcon name="cilCheck" style="color:green" size="sm" height="48" alt="Logo" />
                    </span>
                    <span v-else>
                      <CIcon name="cilXCircle" style="color:red" size="sm" height="48" alt="Logo" />
                    </span>
                  </b-col>
                  <b-col lg="6" md="6">
                    <strong>Distancia (km):</strong> {{nas.distancia}}
                  </b-col>
                  <b-col lg="6" md="6">
                    <strong>Hizo comentario:</strong> 
                    <span v-if="nas.evidencia_comentario">                      
                      <CIcon name="cilCheck" style="color:green" size="sm" height="48" alt="Logo" />
                    </span>
                    <span v-else>
                      <CIcon name="cilXCircle" style="color:red" size="sm" height="48" alt="Logo" />
                    </span>
                  </b-col>
                  <b-col lg="6" md="6">
                    <strong>Tomó foto:</strong>
                    <span v-if="nas.evidencia_foto">                      
                      <CIcon name="cilCheck" style="color:green" size="sm" height="48" alt="Logo" />
                    </span>
                    <span v-else>
                      <CIcon name="cilXCircle" style="color:red" size="sm" height="48" alt="Logo" />
                    </span>
                  </b-col>
                </b-row>

              </section>
            </CCardBody>
          </CCollapse>
        </CCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
  name: "NAS",
  props: ['service'],
  components: {
    Loading
  },
  data(){
    return {
      loading: true,
      accordion: 1,
      nas: null
    }
  },
  created(){
    
  },
  methods: {
    onAccordion(){      
      this.accordion = this.accordion === 0 ? false : 0
      this.fetchNAS()
    },
    fetchNAS(){
      let nasId = this.service.nasId;

      let getNAS = this.$firebase.functions().httpsCallable('getNAS')
      getNAS(nasId).then(response => {
       this.nas = response.data.data  
       this.loading = false      
      }).catch(error => {
        console.log(error)
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>
