<template>
  <div>
    <CCard>
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordion = accordion === 0 ? false : 0"
      >
        <h5 class="m-0">
          Clientes sugeridos: {{ clients != null ? clients.length : 0 }}
        </h5>
      </CButton>
      <CCollapse :show="accordion === 0">
        <CCardBody>
          <b-row v-if="clients != null && clients.length > 0">
            <b-col lg="4" v-for="(client, i) in clients" v-bind:key="i">
              <CCard
                class="mb-0"
                style="margin-top:0.5rem;"
              >
                <CCardBody>
                  <strong>Id:</strong> {{ client.id }} <br />
                  <CIcon name="cilUser" size="sm" height="48" alt="Logo" />
                  {{ client.nombre }}  <br />
                  <CIcon name="cilDog" size="sm" height="48" alt="Logo" />
                  {{ client.pet.name}} <br />
                  <!-- <CIcon name="cilClock" size="sm" height="48" alt="Logo" />
                  {{ service.startDateTime | formatDateTime3 }} -
                  {{ service.endDateTime | formatDateTime3 }} <br /> -->
                  <strong>Barrio:</strong> {{ client.barrio }} 
                  <br />
                  <strong>Zona:</strong>
                  {{ client.zonas ? client.zonas.nombre : "" }} -
                  <!-- <br /> -->
                  <strong>Celular:</strong> {{ client.celular }} <br />
                  <strong>Estado: </strong> 
                  <span v-if="client.cliente_estado">
                    <CBadge 
                      shape="pill" 
                      color="success"
                      v-if="client.cliente_estado.id == 1"
                    >Activo</CBadge>
                     <CBadge 
                      shape="pill" 
                      color="warning"
                      v-if="client.cliente_estado.id == 2"
                    >No recurrente</CBadge>
                  </span>                 
                  <br />
                </CCardBody>
              </CCard>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "clientsToPersuade",
  props: ["clients"],
  data() {
    return {
      accordion: 0
    };
  }
};
</script>

<style lang="scss" scoped></style>
