<template>
  <div>
    <CCard>
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordion = accordion === 0 ? false : 0"
      >
        <h5 class="m-0">Filtros</h5>
      </CButton>
      <CCollapse :show="accordion === 0">
        <CCardBody>
          <b-row>
            <!-- Date -->
            <b-col lg="3">
              Fecha:
              <b-form-group>
                <!-- <CInput 
                  type="date" 
                  placeholder="dd/mm/yyyy" 
                  v-model="date" 
                  :class="{
                    'form-control is-invalid':$v.date.$error,
                    'form-control is-valid': !$v.date.$invalid
                  }"/> -->
                <b-form-datepicker
                  id="date"
                  placeholder="dd/mm/yyyy"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  locale="es"
                  v-model="date"
                  :class="{
                    'form-control is-invalid':$v.date.$error,
                    'form-control is-valid': !$v.date.$invalid
                  }"
                  today-button
                  reset-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <!-- Search -->
            <b-col lg="3"> </b-col>
            <!-- Search By -->
            <b-col lg="3"> </b-col>
            <!-- submit -->
            <b-col lg="3">
              <br />
              <b-button 
                @click="submit" 
                variant="warning"
                :disabled="this.$v.date.$invalid">
                Consultar
              </b-button>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
export default {
  name: "calendarFilters",
  data() {
    return {
      accordion: 0,
      date: null
    };
  },
  validations: {
    date: {
      required
    }
  },
  created() {
    this.date = moment(new Date()).format("YYYY-MM-DD");
  },
  methods: {
    submit() {
      this.$v.$touch();
      if(this.$v.date.$invalid){
        console.log('debes seleccionar fecha')
      }else {
        this.$emit("dateSelected", this.date);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
