<template>
  <div>
    <b-row>      
      <b-col>
        <CCard color="light">
          <CButton
            block
            color="link"
            class="text-left shadow-none card-header"
            @click="accordion = accordion === 0 ? false : 0"
          >
            <h5 class="m-0">Cancelar</h5>            
          </CButton>
          <CCollapse :show="accordion === 0">
            <CCardBody>
              <b-row>
                <b-col>
                  <b-alert v-model="flagAlert" :variant="alertType" dismissible>
                    {{ alertMsg }}
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button variant="info" 
                   @click="submit(67)">
                    Cancelado por lluvia
                  </b-button>
                </b-col>
                <b-col>
                  <b-button variant="warning"
                  @click="submit(68)">
                    Cancelado por cliente
                  </b-button>
                </b-col>
                <b-col>
                  <b-button variant="danger"
                  @click="submit(100)">
                    Cancelado por paseador
                  </b-button>
                </b-col>
                
              
              </b-row>
              <br>
              <b-row v-if="flagCancel">
                <b-col lg="12">
                  Deseas mover a 
                  <strong>
                    {{cancelLabel}} ?
                  </strong>
                </b-col>
                <b-col class="text-center">
                  <b-button @click="cancel" variant="success">
                    SI, cancelar servicio
                  </b-button>
                </b-col>
                <b-col class="text-center">
                  <b-button @click="noContinue">
                    No
                  </b-button>
                </b-col>

              </b-row>
            </CCardBody>
          </CCollapse>
        </CCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "AdditionalActions",
  props: ['service'],
  data(){
    return {
      accordion: 1,
      cancelTypeId: null,
      cancelLabel:'',      
      flagCancel: false,
      flagAlert: false,
      alertMsg: '',
      alertType: 'success'
    }
  },
  created(){
    // let userProfile = JSON.parse(this.$store.getters.userProfile);    
    // console.log(userProfile)
  },
  methods: {
    submit(cancelTypeId){
      this.cancelTypeId = cancelTypeId;
      
      if(cancelTypeId == 67){
        this.cancelLabel =  'Cancelar por lluvia'
      } else if(cancelTypeId == 68){
        this.cancelLabel =  'Cancelado por cliente'
      }else {
        this.cancelLabel =  'Cancelado por paseador'
      }
      this.flagCancel = true
    },
    cancel(){
      let today = new Date();
      let pk = this.service.id 
        + "|" 
        + "operaciones@myapps.pe"
        + "|" 
        + today.getTime();

      let payload = {
        nodeId: this.cancelTypeId,
        pk,
        serviceId: this.service.id
      };

      let cancelService = this.$firebase
        .functions()
        .httpsCallable('cancelService');

      this.flagAlert = false

      cancelService(payload).then(res => {
        let data = res.data
        this.alertType = 'success'
        this.alertMsg = data.description
        this.flagAlert = true
        this.flagCancel = false
      }).catch(error => {
        this.alertType = 'danger'
        this.alertMsg = 'Error en la actualización'
        this.flagAlert = true
        console.log(error)
      })
    
    },
    noContinue(){
      this.flagCancel = false
    }
  }
};
</script>

<style lang="scss" scoped></style>
