<template>
  <div>
    <!-- Generic -->
    <section>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        background-color="#fff"
        :is-full-page="false"
      >
      </loading>
      <b-alert v-model="flagErr" variant="danger" dismissible>
        {{ errMsg }}
      </b-alert>
    </section>

    <!-- Search -->
    <section v-if="!flagErr">
      <b-row>
        <!-- Date -->
        <b-col lg="3">
          Fecha:
          <b-form-group>
            <b-form-datepicker
              id="date"
              placeholder="dd/mm/yyyy"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              locale="es-ES"
              v-model="date"
              :class="{
                'form-control is-invalid': $v.date.$error,
                'form-control is-valid': !$v.date.$invalid
              }"
              v-bind="labels['es-ES'] || {}"
              today-button
              reset-button
            ></b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col lg="7">  
          <strong>Zonas:</strong> 
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="zonesSelected"
            :options="zonesOpts"            
            name="flavour-1">
          </b-form-checkbox-group>   
          io: {{walkerIndicator}}                                      
        </b-col>
        <!-- submit -->
        <b-col lg="2">
          <br />
          <b-button
            @click="submit"
            variant="warning"
            :disabled="this.$v.date.$invalid"
          >
            Consultar
          </b-button>
        </b-col>


      </b-row>
    </section>

    <b-row>
      <b-col lg="12">
        Servicios agendados: {{services != null ? services.length : 0}},
        Servicios finalizados {{servicesFinished.length}}        
      </b-col>
      
      <b-col lg="12">
        <Table
          v-if="items != null"
          :items="items"
          :fields="fields"
          :walkers="walkers"  
          :services="services"          
        />
      </b-col>      
      
      <!-- {{fields}} -->
      <b-col lg="3">
        <PendingList 
          v-if="servicesNotAssigned != null"
          :services="servicesNotAssigned"
          :walkers="walkers"/>
      </b-col>
      <b-col lg="9">
        <ClientsToPersuade 
          v-if="clientsToPersuade != null"
          :clients="clientsToPersuade"
          />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Filters from "./Calendar/Filters";
import PendingList from "./Calendar/PendingList";
import ClientsToPersuade from "./Calendar/ClientsToPersuade";
import Table from "./Calendar/Table";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
export default {
  components: { Loading, PendingList, Filters, Table, ClientsToPersuade },
  name: "calendar",
  data() {
    return {
      loading: true,
      flagErr: false,
      errMsg: "",
      date: null,
      fields: null,
      items: null,
      walkers: null,
      services: null,
      servicesNotAssigned: null,
      userProfile: null,
      flagErr: false,
      errMsg: '',
      labels: {
        'es-ES': {            
          labelPrevYear: 'Año anterior',
          labelPrevMonth: 'Mes anterior',
          labelCurrentMonth: 'Mes actual',
          labelNextMonth: 'Siguiente mes',
          labelNextYear:'Siguiente año',
          labelTodayButton: 'Hoy',
          labelResetButton: 'Limpiar',
          labelHelp: 'Usa el cursor para navegar en las fechas'
        }
      },
      servicesFinished: [],
      clientsToPersuade: null,
      zonesOpts: [
        {value: 1, text: 'Zona 1'},   
      ],
      zonesSelected: [],
      walkerIndicator: 0,
    };
  },
  validations: {
    date: {
      required
    }
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    let access = this.userProfile.acceso.id
    if (access == 1 || access ==2) {
      // 1: owner
      // 2: admin
      this.date = moment(new Date()).format("YYYY-MM-DD");
      this.fetchZones()
    } else {
      this.flagErr = true
      this.errMsg = 'No posees los permisos para este módulo'
      this.loading = false
    }

  },
  methods: {
    fetchZones(){
      let cityId = this.userProfile.ciudades.id

      let getZones = this.$firebase
        .functions()
        .httpsCallable('getZonesByCity')

      getZones(cityId)
        .then(res => {
          let data = res.data.data    
          this.zonesOpts = data
          this.submit()
        }).catch(error => {
          alert(error)
        })
      
    },    
    submit() {
      let payload = {
        date: this.date,
        city: {
          value: this.userProfile.ciudades.id,
          label: this.userProfile.ciudades.nombre
        },
        zones: this.zonesSelected
      };
      this.loading = true
      let getCalendarPayload = this.$firebase
        .functions()
        .httpsCallable("getCalendarPayload");
      getCalendarPayload(payload).then(res => {
        this.loading = false
        let data = res.data.data
        if(data!=null){
          this.fields = data.fields          
          this.walkers = data.walkers
          this.services = data.servicesAssigned                
          this.servicesNotAssigned = data.servicesNotAssigned
          this.items = data.items         
          this.servicesFinished = this.services.filter(service => service.serviceState.id == 34)
          this.clientsToPersuade = data.clientsToPersuade
          this.setIndicator()
        }
      }).catch(error => {
        console.log(error)
        this.loading = false
        this.flagErr = true
        this.errMsg = error
        alert(error)        
      }).finally(_=>this.loading  = false)
    },
    setIndicator(){
      
      this.zonesOpts.map(zone => {
        zone.text = zone.text.split('-')[0]
        let zoneId = zone.value
        let servicesByZone = this.services.filter(service => 
          service.client.zone != null && service.client.zone.id === zoneId)
        let walkersByZone = this.walkers.filter(walker => 
          walker.zone != null && walker.zone.id === zoneId)

        let indicator = 0

        if(servicesByZone.length > 0 && walkersByZone.length > 0){
          indicator = servicesByZone.length / walkersByZone.length
        }
        zone.text += ' - ' + (indicator).toFixed(1)

      })

      let numServices = this.services.length
      let numWalkers = this.walkers.length

      if(numServices > 0 && numWalkers > 0){
        this.walkerIndicator = (numServices / numWalkers).toFixed(1)
      }

    }
  }
};
</script>

<style lang="scss" scoped></style>
